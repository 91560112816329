
/* Header Section */

header{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 2;
  }

  .navbar{
    height: 80px;
    transition: 0.5s;

  }

  .navbar-scrolled{
    height: 80px;
    transition: 0.5s;
    background-color: rgba(17, 17, 17, 0.9);


  }

  #title-container{
    display: flex;
    flex-direction: row;  
    justify-content: left ;
    align-items: center;    
}

  .logo{
    height: 50px;
    width: 50px;
    border-radius: 50%;
    transition: 0.5s;
  
  }

  .logo-scrolled{
    height: 50px;
    border-radius: 50%;
    transition: 0.5s;

  }
  
  nav{
    position: absolute;
    right: 20px;
    height: 100%;
    

  }
  a{
    text-transform: uppercase;
    font-weight: 300;
    font-size: 0.8rem;
    text-decoration: none;
    margin: 0 0.5rem;
    color: #dfdfdf;
    transition: 0.2s ease-in;
    height: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }
  
  a:hover{
    transform: scale(1.08);
    transition: 0.5s;
    color: #228888;
    
  }
  #head-title{
    font-weight: 200;
    font-size: 1rem;
    text-transform: uppercase;
    font-family: Criteria;
    font-size: 28px;
    margin: 0;
    margin-left: 2rem;
    color: #dfdfdf;

  }
  
  @media only screen and (max-width: 600px) {
    #head-title {
      margin-left: 20px;
    }
    #contact-link{
      display: none;
    }
  }