* {
  box-sizing: border-box;
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
  font-size: 16px;
  font-weight: 300;
}

@font-face {
  font-family: Gotham;
  src: url(./fonts/Gotham-Light.otf);
  font-display: fallback;
}

@font-face {
  font-family: Criteria;
  src: url(./fonts/criteria-extrabold.otf);
  font-display: fallback;
}


p{
  font-size: 1.25rem;
  opacity: 0.8;
}

body{
  background-color: #fafafa;
  font-family: 'Roboto', sans-serif;
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
}

button:hover, a:hover{
  cursor: pointer;
}


body::-webkit-scrollbar { /* WebKit */
  width: 0;
  height: 0;
}

html{
  scroll-behavior:smooth;
  padding: 0;
  margin: 0;
  height: 100vh;
}


.gradient-text {
  background-color: #f3ec78;
  background-image: linear-gradient(45deg, #a35dd4,#541b9c, #a51b86);
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent; 
  -moz-text-fill-color: transparent;
  font-size: 82px;
  font-weight: bolder;
  font-family: criteria;
}

main{
  position: absolute;
  top: 12vh;
  padding-top: 1rem;
}


.work-img{
  width: 60vw;
  margin: 0 20vw;
  box-shadow: 5px 10px 10px #cdcdcd;;
}



h3{
  font-weight: 200;
}

p{
  font-weight: 300;
}

footer{
  min-height: 100px;
  width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  background-color: #1e1e1e;
  color: #dfdfdf;
}

#contact-side-btn{
  display: none;
}

.frantech-btn{
  background-color: unset;
  border-image-slice: 1;
  border-width: 1.5px;
  border-image-source: linear-gradient(45deg, rgba(163, 93, 212, 0), rgba(250, 250, 250, 1));
  color: white;
  height: 48px;
  min-width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.7;
  transition: 0.5s;
  padding: 10px;
  margin: 1rem;
  text-transform: uppercase;
}

.frantech-btn:hover{
  opacity: 1;
  transition: 0.5s;
}
.secondary-btn{
  background-color: unset;
  border-image-slice: 1;
  border-width: 1.5px;
  border-image-source: linear-gradient(45deg, rgba(250, 250, 250, 0), rgba(17, 17, 17, 1));
  color: #1e1e1e;
  height: 48px;
  min-width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.7;
  transition: 0.5s;
  padding: 10px;
  margin: 1rem;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 900;
}



@media only screen and (max-width: 600px) {
  #contact-side-btn{
    display: block;
    position: fixed;
    background-color: #288;
    color: white;
    font-weight: bold;
    position: fixed;
    bottom: 0;
    right: 5px;
    height: 50px;
    width: 150px;
    max-width: 40vw;
    border: unset;
    box-shadow: rgba(0, 0, 0, 0.35) 0px -3px 15px;
    z-index: 999;
    border-radius: 15px 15px 0 0;

  }

 
  .gradient-text{
    font-size: 64px;
  }

  body{
    cursor: default;
    height: 100vh;
  }

  .cursor{
    display: none;
  }
 
}